import React, { useState } from 'react';
import SectionTitle from '../components/about/section-title/SectionTitle';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import IconsBar from '../components/shared/icons-bar/IconsBar';
import ImageSliderWithThumbnail from '../components/shared/slider-with-thumbnail/image/ImageSliderWithThumbnail';
import DetailsNavTile from '../components/portfolio/details-nav/DetailsNavTile';
import styles from './portfolio-details-template.module.scss';
import { Project } from '../components/portfolio/interfaces';
import SlabInventoryPopUp from '../components/custom/slab-inventory-popup/SlabInventoryPopUp';
import useViewport from '../hooks/useViewport';
import { FavoriteTypes } from '../components/shared/enum/favorite-data-type';
import useFavourite from '../hooks/useFavourite';
import cn from 'classnames';

const PortfolioDetailsPage: React.FC<Props> = ({ pageContext }: Props) => {
  const project = pageContext.project;
  const { isMobile } = useViewport();
  const favoriteAPI = useFavourite();

  const [finishId, setFinishId] = useState(0);

  const [isFavoriteSaved, setIsFavoriteSaved] = React.useState(false);

  const setSavedFavorites = (isFavoriteSaved: boolean) => {
    setIsFavoriteSaved(isFavoriteSaved);
  };

  return (
    <Layout isWhiteBackground={true}>
      <SEO title={project.Name} />
      <section className={styles.wrapper}>
        <div className={styles.titleBox}>
          <SectionTitle title={project.Name} withLine={true} uppercase={false} fontSize="2.8rem" />
          {!isMobile && (
            <div className={styles.creatorsBox}>
              {project.architect && (
                <div className={styles.creator}>
                  <span className={styles.subtitle}>architect</span>
                  <h1 className={styles.title}>{project.architect}</h1>
                </div>
              )}
              {project.designer && (
                <div className={styles.creator}>
                  <span className={styles.subtitle}>designer</span>
                  <h1 className={styles.title}>{project.designer}</h1>
                </div>
              )}
              {project.contractor && (
                <div className={styles.creator}>
                  <span className={styles.subtitle}>contractor</span>
                  <h1 className={styles.title}>{project.contractor}</h1>
                </div>
              )}
              {project.landscape_architect && (
                <div className={styles.creator}>
                  <span className={styles.subtitle}>landscape architect</span>
                  <h1 className={styles.title}>{project.landscape_architect}</h1>
                </div>
              )}
              {project.fabricator && (
                <div className={styles.creator}>
                  <span className={styles.subtitle}>fabricator</span>
                  <h1 className={styles.title}>{project.fabricator}</h1>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={styles.sliderBox}>
          <nav className={styles.iconsBar}>
            {/*TODO add project pdf link*/}
            <IconsBar
              imageUrl={project.project_images[finishId]?.image.url}
              link={'project.description'}
              isFavoriteSaved={isFavoriteSaved}
            >
              <SlabInventoryPopUp
                favouriteAPI={favoriteAPI}
                title={project.Name}
                imagesPreview={project.images[finishId]?.url}
                collectionId={project.id}
                collectionType={FavoriteTypes.PROJECT}
                setSavedFavorites={setSavedFavorites}
              />
            </IconsBar>
          </nav>
          <section className={styles.slider}>
            <ImageSliderWithThumbnail
              slideHeight={'500px'}
              images={project.project_images}
              setFinishId={setFinishId}
            />
          </section>
        </div>

        {isMobile && (
          <div className={styles.creatorsBox}>
            {project.architect && (
              <div className={styles.creator}>
                <span className={styles.subtitle}>architect</span>
                <h1 className={styles.title}>{project.architect}</h1>
              </div>
            )}
            {project.designer && (
              <div className={styles.creator}>
                <span className={styles.subtitle}>designer</span>
                <h1 className={styles.title}>{project.designer}</h1>
              </div>
            )}
            {project.contractor && (
              <div className={styles.creator}>
                <span className={styles.subtitle}>contractor</span>
                <h1 className={styles.title}>{project.contractor}</h1>
              </div>
            )}
            {project.landscape_architect && (
              <div className={styles.creator}>
                <span className={styles.subtitle}>landscape architect</span>
                <h1 className={styles.title}>{project.landscape_architect}</h1>
              </div>
            )}
            {project.fabricator && (
              <div className={styles.creator}>
                <span className={styles.subtitle}>fabricator</span>
                <h1 className={styles.title}>{project.fabricator}</h1>
              </div>
            )}
          </div>
        )}
        <div className={styles.navBox}>
          <a
            href={project.External_link ? `${project.External_link}` : ''}
            target="_blank"
            rel="noreferrer"
            className={cn({ [styles.hidden]: !project.External_link_is_shown })}
          >
            <button className={styles.tourButton}>TAKE A VIRTUAL TOUR</button>
          </a>
          <DetailsNavTile {...pageContext} />
        </div>
      </section>
    </Layout>
  );
};

interface Props {
  pageContext: {
    nextName: string;
    nextUrl: string;
    prevName: string;
    prevUrl: string;
    project: Project;
  };
}

export default PortfolioDetailsPage;
