import { Link } from 'gatsby';
import React from 'react';
import ArrowButton from '../../shared/arrow-button/ArrowButton';
import styles from './DetailsNavTile.module.scss';
import useViewport from '../../../hooks/useViewport';

interface Props {
  prevUrl: string;
  nextUrl: string;
  prevName: string;
  nextName: string;
}

const DetailsNavTile: React.FC<Props> = ({ prevUrl, nextUrl, prevName, nextName }: Props) => {
  const { isMobile, isTablet } = useViewport();

  return (
    <div className={styles.container}>
      <Link to={prevUrl} className={styles.leftBox + ' ' + styles.box}>
        <span className={styles.subtitle}>PREVIOUS PROJECT</span>
        <h1 className={styles.title}>{prevName}</h1>
      </Link>
      <Link to="/portfolio" className={styles.centerBox + ' ' + styles.box}>
        <ArrowButton
          name={isMobile ? 'SEE ALL PROJECTS' : 'BACK TO PORTFOLIO'}
          imgLeftMargin={isTablet ? '0' : '2.5rem'}
          fontSize={isTablet ? '1.3rem' : '1.6rem'}
          imgHeight={isTablet ? '1.3rem' : '2.5rem'}
        />
      </Link>
      <Link to={nextUrl} className={styles.rightBox + ' ' + styles.box}>
        <span className={styles.subtitle}>NEXT PROJECT</span>
        <h1 className={styles.title}>{nextName}</h1>
      </Link>
    </div>
  );
};

export default DetailsNavTile;
